import { Routes, Route, Navigate } from 'react-router-dom';
import routes from './app-routes';
import { SingleCard } from './layouts';
import { LoginForm, ResetPasswordForm, ChangePasswordForm, CreateAccountForm } from './components';

export default function UnauthenticatedContent() {
  return (
    <Routes>
    {routes.map(({ path, element }) => (
      <Route
        key={path}
        path={path}
        element={element}
      />
    ))}
    <Route
      path='*'
      element={<Navigate to='/monitor-klein' />}
    />
  </Routes>
  );
}
