


import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/auth';
import DataGrid, {
  Column,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { HubConnectionBuilder, HttpTransportType } from '@aspnet/signalr';
//import './styles.css'; // Tell webpack that Button.js uses these styles
import DataSource from 'devextreme/data/data_source';


export default function Monitor_klein() {
 
  useEffect(() => {
  

    const hubConnection = new HubConnectionBuilder()
      .withUrl('https://signalr.csdresden.de/scoreboardHub', {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .build();

    const store = new CustomStore({

      load: () => hubConnection.invoke('GetSummen'),
      key: 'id',
    });
    const ds = new DataSource({
      store: store,
      sort: [
        { selector: 'rp', desc: true }],
      reshapeOnPush: true,
    })
    hubConnection
      .start()
      .then(() => {
        hubConnection.on('UpdateSummen', (data) => {
          data.forEach(function (data2){
            
            store.push([{ type: 'update', data: data2, key: data2.id }]);
          });

       //   
        });
        setdataSource(ds);
      

      });




      const hubConnection2 = new HubConnectionBuilder()
      .withUrl('https://signalr.csdresden.de/scoreboardHub', {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .build();

    const store2 = new CustomStore({

      load: () => hubConnection.invoke('GetTop5'),
      key: 'row_number_all',
    });
    const ds2 = new DataSource({
      store: store2,
      sort: [
        { selector: 'rp', desc: true }],
      reshapeOnPush: true,
    })
    hubConnection2
      .start()
      .then(() => {
        hubConnection2.on('UpdateTop5', (data) => {
          data.forEach(function (data2){
       
        store2.push([{ type: 'update', data: data2, key: data2.row_number_all }]);
          });
       
        });
        setdataSource2(ds2);
      

      });


  }, []);


  const [dataSource, setdataSource] = useState('')
  const [dataSource2, setdataSource2] = useState('')

  function DataRow(rowInfo) {
    return (
   
       <React.Fragment>
       <tr className="main-row">
         <td ><h2 style={{ fontWeight:"bolder",marginTop: "-20px", marginBottom: "0px" }}>{rowInfo.data.teamName}</h2></td>
      </tr>
       <tr className="notes-row">
      
       <td><div><h2 style={{  fontWeight:"bolder", textAlign: "right", marginTop: "0px", marginBottom: "0px" }}>Pkt.: {rowInfo.data.rp?.toFixed(2)}</h2></div></td>
      

       </tr>
       <tr >
       <td colSpan="1">
       <hr style={{ marginTop: "0px", marginBottom: "0px", borderWidth:"5px" }}  ></hr>
       </td>
       
       </tr>
     
     </React.Fragment>
    );
  }
  function DataRow2(rowInfo) {
    return (
      <React.Fragment>
        <tr className="main-row">
      
          <td colSpan="3"><h2 style={{ marginTop: "-20px", marginBottom: "-15px", fontWeight:"bolder" }}>{rowInfo.data.firstname} {rowInfo.data.lastname}</h2></td>
       </tr>
        <tr className="notes-row">
        <td style={{  paddingBottom:"0px" }}><div><h3 style={{ marginTop: "0px", marginBottom: "0px" }}>{rowInfo.data.distance} {rowInfo.data.stroke}</h3></div></td>
        <td style={{  paddingBottom:"0px" }}><div><h3 style={{ marginTop: "0px", marginBottom: "0px" }}>{rowInfo.data.timeprint}</h3></div></td>
        <td style={{  paddingBottom:"0px" }}><div><h3 style={{ marginTop: "0px", marginBottom: "0px" }}>Pkt.: {rowInfo.data.rp?.toFixed(2)}</h3></div></td>
       

        </tr>
        <tr >
        <td colSpan="3">
        <hr style={{ marginTop: "0px", marginBottom: "0px", borderWidth:"5px"
       }}  ></hr>
        </td>
        
        </tr>
      
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <table style={{ width: "100%", height: "100%", backgroundColor: "black", color: "white" }} border="0">
        <tbody>
          <tr>
            <td style={{ width: "45%", height: "100%" }}>
              <DataGrid
                id="gridContainer"
                dataSource={dataSource}
                showBorders={true}
                repaintChangesOnly={false}
                highlightChanges={true}
               height={"100%"}
                width={"100%"}
                dataRowRender={DataRow}
                showColumnHeaders={false}
                
              >
                <Column></Column>
              </DataGrid>


            </td>
            <td style={{ width: "55%", height: "100%" }}>   <DataGrid
                id="gridContainer"
                dataSource={dataSource2}
                showBorders={true}
                repaintChangesOnly={false}
                highlightChanges={true}
                height={"100%"}
                width={"100%"}
                dataRowRender={DataRow2}
                showColumnHeaders={false}
              > <Column></Column></DataGrid></td>

          </tr>

        </tbody>
      </table>
    </React.Fragment>
  );
}

